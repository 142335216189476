.pointer {
    cursor: pointer;
}

.strike {
    text-decoration: line-through;
}

ion-icon {
    margin-top: 7px;
}

/* for login buttons */
.is-blue {
    background: #45B4DC !important;
}

/*body {*/
/*  font-family: 'open sans', sans-serif;*/
/*  font-size: 16px;*/
/*}*/



/*thead th {*/
/*  text-align: left;*/
/*  border-bottom: 2px solid black;*/
/*}*/

/*thead button {*/
/*  border: 0;*/
/*  border-radius: none;*/
/*  font-family: inherit;*/
/*  font-weight: 700;*/
/*  font-size: inherit;*/
/*  padding: 0.5em;*/
/*  margin-bottom: 1px;*/
/*}*/

/*thead button.ascending::after {*/
/*  content: '👇';*/
/*  display: inline-block;*/
/*  margin-left: 1em;*/
/*}*/

/*thead button.descending::after {*/
/*  content: '☝️';*/
/*  display: inline-block;*/
/*  margin-left: 1em;*/
/*}*/

/*tbody td {*/
/*  padding: 0.5em;*/
/*  border-bottom: 1px solid #ccc;*/
/*}*/

/*tbody tr:hover {*/
/*  background-color: #eee;*/
/*}*/
